import React, { useState, useEffect } from "react";
import StudentDashboard from "../Dashboard/StudentDashboard";
import Header from "../Header/Header";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import "./Cart.css";
function Cart() {
  const navigate = useNavigate();
  const [flag, setFlag] = useState(true);
  const [productDetails, setProductDetails] = useState([]);
  const userDetails1 = JSON.parse(window.localStorage.getItem("userdetails"));
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") != "1"
    ) {
      alert(
        "You are not authorized to access this page. Login with student credentials"
      );
      navigate("/login");
    }
  });

  useEffect(() => {
    if (flag) {
      axios
        .get(
          "https://axl8622.uta.cloud/api/public/cartitems.php?id=" + userDetails1["id"]
        )
        .then((result) => {
          let data = result.data.user_detail;
          const total1 = result.data.total.total;
          if (data["error"]) {
            data = [];
            total1 = 0;
          }
          setProductDetails(data);
          setTotal(total1);
          console.log(data);
        });
    }
    setFlag(false);
  });

  return (
    <>
      {/* <Header/> */}
      <StudentDashboard />
      <div className="car" style={{ marginLeft: "20%" }}>
        <div className="CartContainer">
          <div className="Header">
            <h3 className="Heading">Shopping Cart</h3>
            {/* <h5 className="Action">Remove all</h5> */}
          </div>

          {productDetails.map((product) => {
            return (
              <div className="Cart-Items pad">
                <img src={product["image"]} style={{ height: "120px" }} />
                <p>{product["name"]}</p>
                <p>${product["price"]}</p>
              </div>
            );
          })}

          <hr />
          <div className="checkout">
            <div className="total">
              <div>
                <div className="Subtotal">Sub-Total : ${total}</div>
              </div>
            </div>
            <a href={"/payment/" + total}>
              <button className="button">Checkout</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cart;
