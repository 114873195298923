import React, { useState, useEffect } from "react";
import "./EditForm.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function AddForm() {
  const { a } = useParams();
  const type = "User";
  let location = useLocation();
  console.log(location);
  location = location.pathname;
  let op = location.includes("add") ? "Add" : "Update";
  const [effectFlag, setEffectFlag] = useState(true);
  const userDetails1 = JSON.parse(window.localStorage.getItem("userdetails"));

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [address, setaddress] = useState("");
  const navigate = useNavigate();
  const usertype = window.localStorage.getItem("user");

  useEffect(() => {
    if (effectFlag) {
      const usertype = window.localStorage.getItem("user");
      if (
        usertype == "2" ||
        usertype == "3" ||
        (usertype == "1" && a == userDetails1["id"])
      ) {
        axios
          .get("https://axl8622.uta.cloud/api/public/updateuser.php?id=" + a)
          .then((result) => {
            console.log(result.data);
            setname(result.data.full_name);
            setemail(result.data.email_id);
            setphone(result.data.phone_num);
            setaddress(result.data.address);
          });
      } else {
        alert(
          "You are not authorized to access this page. Login with admin credentials"
        );
        navigate("/login");
      }
    }
    setEffectFlag(false);
  });

  const onnameChange = (e) => {
    setname(e.target.value);
  };
  const onaddresschange = (e) => {
    setaddress(e.target.value);
  };
  const onemailchange = (e) => {
    setemail(e.target.value);
  };
  const onphonechange = (e) => {
    setphone(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const userdata = {};
    userdata["name"] = name;
    userdata["email"] = email;
    userdata["phone"] = phone;
    userdata["address"] = address;
    userdata["id"] = a;

    axios
      .post("https://axl8622.uta.cloud/api/public/updateuser.php", userdata)
      .then(function (response) {
        console.log(response.data);
        console.log(response.data.status);
        if (response.data.status_code == 1) {
          if (usertype == "3") {
            navigate("/superadmin/users/");
          } else if (usertype == "2") {
            navigate("/schooladmin/users/");
          } else if (usertype == "1") {
            navigate("/student/profile");
          }
        }
      });

    event.target.reset();
  };

  return (
    <div>
      <form className="form-style-9" onSubmit={handleSubmit}>
        <h1>{type}</h1>
        <ul>
          <li>
            <input
              value={name}
              type="text"
              name="field1"
              className="field-style field-split align-left"
              placeholder={type + " Name"}
              onChange={onnameChange}
              required
            />
            <input
              value={email}
              type="email"
              name="field2"
              className="field-style field-split align-right"
              placeholder={type + " Email"}
              onChange={onemailchange}
              required
            />
          </li>
          <li>
            <input
              value={phone}
              type="text"
              name="field3"
              className="field-style field-split align-left"
              placeholder="Phone"
              onChange={onphonechange}
            />
            <input
              value={address}
              type="text"
              name="field4"
              className="field-style field-split align-right"
              placeholder="Address"
              onChange={onaddresschange}
            />
          </li>
          {/* <li>
    <input type="text" name="field3" className="field-style field-full align-none" placeholder="Subject" />
    </li> */}
          {/* <li>
    <textarea name="field5" className="field-style" placeholder="Message"></textarea>
    </li> */}
          <li>
            <input type="submit" value={op} />
          </li>
        </ul>
      </form>
    </div>
  );
}

export default AddForm;
