import "./EditForm.css";
import { useLocation } from "react-router-dom";
import React, { useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

function AddProduct() {
  let location = useLocation();
  location = location.pathname;
  const type = location.indexOf("add") > -1 ? "Add" : "Update";
  const navigate = useNavigate();
  const userDetails1 = JSON.parse(window.localStorage.getItem("userdetails"));

  const name = useRef(null);
  const price = useRef(null);
  const qty = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    const userdata = {};
    userdata["name"] = name.current.value;
    userdata["price"] = price.current.value;
    userdata["qty"] = qty.current.value;
    userdata["id"] = userDetails1["id"];

    axios
      .post("https://axl8622.uta.cloud/api/public/addproduct.php", userdata)
      .then(function (response) {
        console.log(response.data);
        console.log(response.data.status);
        if (response.data.status_code == 1) {
          navigate("/business/products/");
        }
      });

    event.target.reset();
  };

  return (
    <div>
      <form className="form-style-9" onSubmit={handleSubmit}>
        <h1>Product</h1>
        <ul>
          <li>
            <input
              ref={name}
              type="text"
              name="field1"
              className="field-style field-split align-left"
              placeholder="Name"
              required
            />
          </li>
          <li>
            <input
              type="text"
              ref={price}
              name="field2"
              className="field-style field-split align-right"
              placeholder="Price"
              required
            />
          </li>

          <li>
            <input
              type="text"
              ref={qty}
              name="field2"
              className="field-style field-split align-right"
              placeholder="Quantity"
            />
          </li>

          <li>
            <input type="submit" value={type} />
          </li>
        </ul>
      </form>
    </div>
  );
}

export default AddProduct;
