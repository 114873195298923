import React, { useRef } from "react";
import Header from "../Header/Header";
import "./EditForm.css";
import { useLocation } from "react-router-dom";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function ValidateEmail() {
  let location = useLocation();
  location = location.pathname;
  const navigate = useNavigate();
  const email_code = useRef(null);
  const params = useParams();
  const email = params.emailid;

  const handleSubmit = (event) => {
    event.preventDefault();

    const userdata = {};
    userdata["email_id"] = email;
    userdata["email_code"] = email_code.current.value;

    console.log(userdata);

    axios
      .post("https://axl8622.uta.cloud/api/public/validate_email.php", userdata)
      .then(function (response) {
        console.log(response.data);
        console.log(response.data.status);
        if (response.data.status == 1) {
          alert("OTP validated. Login again");
          navigate("/login");
        } else {
          alert("OTP not valid. Validate again");
          //   navigate("/login");
        }
      });

    event.target.reset();
  };

  return (
    <>
      <div>
        <form className="form-style-9" onSubmit={handleSubmit}>
          <h1>Validate Email Id</h1>
          <ul>
            <li>
              <input
                value={email}
                type="text"
                name="field1"
                className="field-style field-split align-left"
                placeholder={"Title"}
                required
                disabled
              />
            </li>

            <li>
              <input
                ref={email_code}
                type="text"
                name="field3"
                className="field-style field-split align-left"
                placeholder="OTP"
                required
              />
            </li>

            <li>
              <input type="submit" />
            </li>
          </ul>
        </form>
      </div>
    </>
  );
}

export default ValidateEmail;
