import React from "react";
import Header from "../Header/Header";
import "./About.css";

const About = () => {
  return (
    <>
      <Header />
      <div className="aboutc">
        <div className="About-us">
          <h1 className="aboutHeading"> About Us </h1>
          <br /> <br /> <br />
          <img src="https://s3.ap-south-1.amazonaws.com/s3.studytonight.com/tutorials/uploads/pictures/1628677506-101156.png" />
          <p>
          Mercado Escolar acts as a mediator between the students and business owners. The website's sole purpose is to help students find products they 
need at an affordable price while also giving them the flexibility to return or resell the products that they are no longer using.
</p>
          <p>
Also. Mercado Escolar aids the business owners in effectively identifying their target markets.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
