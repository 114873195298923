import React, { useState, useEffect } from "react";
import "./Admin.css";
import { NavLink } from "react-router-dom";
import Header from "../Header/Header";
import axios from "axios";
import BusinessDashboard from "../Dashboard/BusinessDashboard";

const BusinessUser = () => {
  const [flag, setFlag] = useState(true);
  const [insights, setInsights] = useState([]);
  useEffect(() => {
    if (flag) {
      axios.get("https://axl8622.uta.cloud/api/public/insights.php").then((result) => {
        let data = result.data;
        if (data["error"]) {
          data = [];
        }
        setInsights(data);
        console.log("Insights");
        console.log(data);
      });
    }
    setFlag(false);
  });
  return (
    <>
      {/* <Header/> */}
      <BusinessDashboard />

      <section className="dashboard">
        <div className="top1">
          <i className="uil uil-bars sidebar-toggle"></i>

          <div className="search-box1">
            <i className="uil uil-search"></i>
            <input type="text" placeholder="Search here..." />
          </div>
        </div>
        <div className="dash-content">
          <div className="overview">
            <div className="title">
              <i className="uil uil-tachometer-fast-alt"></i>
              <span className="text">Dashboard</span>
            </div>

            <div className="boxes">
              <div className="box box1">
                <i className="uil uil-thumbs-up"></i>
                <span className="text">Products</span>
                <span className="number">{insights["product_count"]}</span>
              </div>
              <div className="box box2">
                <i className="uil uil-comments"></i>
                <span className="text">Sales</span>
                <span className="number">{insights["orders_count"]}</span>
              </div>
              <div className="box box3">
                <i className="uil uil-share"></i>
                <span className="text">Profits</span>
                <span className="number">{insights["total_sale"]}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BusinessUser;
