import React from "react";
import Header from "../Header/Header";
import "./Services.css";

{/* 
Any student can register with the system in no time and without any hassle.
Students can buy and resell the products as they see fit.
Students can also join multiple clubs and find people with similar interests.
Students can create posts and edit and delete them on the go.
Business owners can post advertisements to make their products reach wider range of audience.
Business owners can add, update and delete any products seamlessly.

*/}
const Services = () => {
  return (
    <>
      <Header />
      <div className="serv">
        <div className="services-section">
          <div className="inner-width">
            <h1 className="section-title">Our Services</h1>
            <div className="border"></div>
            <div className="services-container">
              <div className="service-box">
                <div className="service-desc">
                Any student can register with the system in no time and without any hassle.
                </div>
              </div>
              <div className="service-box">
                <div className="service-desc">
                Students can buy and resell the products as they see fit.
                </div>
              </div>
              <div className="service-box">
                <div className="service-desc">
                Students can also join multiple clubs and find people with similar interests.
                </div>
              </div>
              <div className="service-box">
                <div className="service-desc">
                Students can create posts and edit and delete them on the go.
                </div>
              </div>
              <div className="service-box">
                <div className="service-desc">
                Business owners can post advertisements to make their products reach wider range of audience.
                </div>
              </div>
              <div className="service-box">
                <div className="service-desc">
                Business owners can add, update and delete any products seamlessly.
                </div>
              </div>              
              <div className="service-box">
                <div className="service-desc">
                Business owners can also create clubs and find people with similar interests.
                </div>
              </div>
              <div className="service-box">
                <div className="service-desc">
                Business owners can create posts and edit and delete them on the go.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
