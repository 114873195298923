import React from "react";
import Header from "../Header/Header";
import ContactForm from "../widgets/ContactForm";

const Contact = () => {
  return (
    <>
      <Header />
      <ContactForm />
    </>
  );
};

export default Contact;
