import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import "./EditForm.css";
import { useLocation } from "react-router-dom";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function EditClub() {
  let location = useLocation();
  location = location.pathname;
  const navigate = useNavigate();
  const type = location.indexOf("add") > -1 ? "Add" : "Update";
  const userDetails1 = JSON.parse(window.localStorage.getItem("userdetails"));
  const [postname, setadname] = useState("");
  const [description, setdesc] = useState("");
  const [effectFlag, setEffectFlag] = useState(true);
  const params = useParams();

  useEffect(() => {
    if (effectFlag) {
      const usertype = window.localStorage.getItem("user");
      if (usertype == "2") {
        axios
          .get("https://axl8622.uta.cloud/api/public/editclubs.php?id=" + params.a)
          .then((result) => {
            console.log(result.data);
            setadname(result.data.name);
            setdesc(result.data.description);
          });
      } else {
        alert(
          "You are not authorized to access this page. Login with business owner credentials"
        );
        navigate("/login");
      }
    }
    setEffectFlag(false);
  });

  const onnameChange = (e) => {
    setadname(e.target.value);
  };
  const ondescChange = (e) => {
    setdesc(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const userdata = {};
    userdata["name"] = postname;
    userdata["description"] = description;
    userdata["id"] = params.a;

    axios
      .post("https://axl8622.uta.cloud/api/public/editclubs.php", userdata)
      .then(function (response) {
        console.log(response.data);
        console.log(response.data.status);
        if (response.data.status_code == 1) {
          navigate("/schooladmin/clubs");
        }
      });

    event.target.reset();
  };

  return (
    <>
      <div>
        <form className="form-style-9" onSubmit={handleSubmit}>
          <h1>Club</h1>
          <ul>
            <li>
              <input
                value={postname}
                type="text"
                name="field1"
                className="field-style field-split align-left"
                placeholder={"Title"}
                onChange={onnameChange}
                required
              />
            </li>

            <li>
              <input
                value={description}
                type="text"
                name="field3"
                className="field-style field-split align-left"
                placeholder="Description"
                onChange={ondescChange}
                required
              />
            </li>

            <li>
              <input type="submit" />
            </li>
          </ul>
        </form>
      </div>
    </>
  );
}

export default EditClub;
