import "./Content.css";
import SchoolAdminDashboard from "../Dashboard/SchoolAdminDashboard";
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import axios from "axios";

function SchoolAdminContent() {
  const navigate = useNavigate();
  const [flag, setFlag] = useState(true);
  const [userDetails, setUserDetails] = useState(true);
  const [productDetails, setProductDetails] = useState([]);
  const userDetails1 = JSON.parse(window.localStorage.getItem("userdetails"));

  useEffect(() => {
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") != "2"
    ) {
      alert(
        "You are not authorized to access this page. Login with school admin credentials"
      );
      navigate("/login");
    }
  });

  useEffect(() => {
    if (flag) {
      axios.get("https://axl8622.uta.cloud/api/public/posts.php").then((result) => {
        let data = result.data;
        setProductDetails(data);
        console.log(data);
      });
    }
    setFlag(false);
  });
  return (
    <>
      {/* <Header/> */}
      <SchoolAdminDashboard />
      <div className="card-lis">
        <br />
        <div>
          <center>
            <h1>Posts</h1>
          </center>
        </div>
        <div>
          <NavLink to={"add/4"}>
            <button style={{ float: "right", margin: "2% 5%" }}>
              <span className="data-title" style={{ float: "right" }}>
                Add Post
              </span>
            </button>
          </NavLink>
        </div>
        <ul className="card-wrapper">
          {productDetails.map((product) => {
            return (
              <li className="card">
                <img src={product["image"]} alt="" />
                <p>{product["name"]}</p>
                <p>{product["description"]}</p>
                <NavLink to={"/schooladmin/content/update/" + product["id"]}>
                  <button
                    style={{ padding: "20px 25px", marginBottom: "10px" }}
                  >
                    Update
                  </button>
                </NavLink>
                <NavLink to={"/schooladmin/content/delete/" + product["id"]}>
                  <button
                    style={{ padding: "20px 25px", marginBottom: "10px" }}
                  >
                    Delete
                  </button>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default SchoolAdminContent;
