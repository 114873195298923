import "./EditForm.css";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function EditProduct() {
  let location = useLocation();
  location = location.pathname;
  const navigate = useNavigate();
  const userDetails1 = JSON.parse(window.localStorage.getItem("userdetails"));
  const [price, setprice] = useState("");
  const [productname, setproductname] = useState("");
  const [qty, setqty] = useState("");
  const [effectFlag, setEffectFlag] = useState(true);
  const params = useParams();

  useEffect(() => {
    if (effectFlag) {
      const usertype = window.localStorage.getItem("user");
      if (usertype == "4") {
        axios
          .get(
            "https://axl8622.uta.cloud/api/public/editproduct.php?product_id=" +
              params.a
          )
          .then((result) => {
            console.log(result.data);
            setproductname(result.data.name);
            setprice(result.data.price);
            setqty(result.data.qty);
          });
      } else {
        alert(
          "You are not authorized to access this page. Login with business owner credentials"
        );
        navigate("/login");
      }
    }
    setEffectFlag(false);
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    const userdata = {};
    userdata["name"] = productname;
    userdata["price"] = price;
    userdata["qty"] = qty;
    userdata["id"] = params.a;

    axios
      .post("https://axl8622.uta.cloud/api/public/editproduct.php", userdata)
      .then(function (response) {
        console.log(response.data);
        console.log(response.data.status);
        if (response.data.status_code == 1) {
          navigate("/business/products/");
        }
      });

    event.target.reset();
  };

  const onproductnameChange = (e) => {
    setproductname(e.target.value);
  };
  const onpriceChange = (e) => {
    setprice(e.target.value);
  };
  const onqtyChange = (e) => {
    setqty(e.target.value);
  };

  return (
    <div>
      <form className="form-style-9" onSubmit={handleSubmit}>
        <h1>Product</h1>
        <ul>
          <li>
            <input
              value={productname}
              type="text"
              name="field1"
              className="field-style field-split align-left"
              placeholder="Name"
              onChange={onproductnameChange}
              required
            />
          </li>
          <li>
            <input
              type="text"
              value={price}
              name="field2"
              className="field-style field-split align-right"
              placeholder="Price"
              onChange={onpriceChange}
              required
            />
          </li>

          <li>
            <input
              type="text"
              value={qty}
              name="field2"
              className="field-style field-split align-right"
              placeholder="Quantity"
              onChange={onqtyChange}
            />
          </li>

          <li>
            <input type="submit" />
          </li>
        </ul>
      </form>
    </div>
  );
}

export default EditProduct;
