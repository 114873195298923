import React, { useState, useEffect } from "react";
import StudentDashboard from "../Dashboard/StudentDashboard";
import "./Club.css";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import axios from "axios";

function Club() {
  const navigate = useNavigate();
  const [flag, setFlag] = useState(true);
  const [userDetails, setUserDetails] = useState(true);
  const [productDetails, setProductDetails] = useState([]);
  const [clubdetails, setclubdetails] = useState([]);
  const [joinclubs, setJoinedClubs] = useState([]);
  const [clubs, setClubs] = useState([]);
  const userDetails1 = JSON.parse(window.localStorage.getItem("userdetails"));

  useEffect(() => {
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") != "1"
    ) {
      alert(
        "You are not authorized to access this page. Login with student credentials"
      );
      navigate("/login");
    }
  });

  useEffect(() => {
    if (flag) {
      axios
        .get(
          "https://axl8622.uta.cloud/api/public/joinedclubs.php?uid=" +
            userDetails1["id"]
        )
        .then((result) => {
          let data = result.data;
          if (data["error"]) {
            data = [];
          }
          setProductDetails(data);
          console.log(data);
        });
      axios
        .get("https://axl8622.uta.cloud/api/public/clubs.php?id=" + userDetails1["id"])
        .then((result) => {
          let data = result.data;
          if (data["error"]) {
            data = [];
          }
          setclubdetails(data);
          console.log(data);
        });
      axios
        .get(
          "https://axl8622.uta.cloud/api/public/joinedclubs.php?userid=" +
            userDetails1["id"]
        )
        .then((result) => {
          let data = result.data;
          if (data["error"]) {
            data = [];
          }
          setJoinedClubs(data);
          console.log(data);
        });
    }
    setFlag(false);
  });

  return (
    <>
      {/* <Header/> */}
      <StudentDashboard />
      <div className="card-lis">
        <br />
        <div>
          <center>
            <h1>Clubs</h1>
          </center>
        </div>
        <div>
          <NavLink to={"add/5"}>
            <button style={{ float: "right", margin: "2% 5%" }}>
              <span className="data-title" style={{ float: "right" }}>
                Create Club
              </span>
            </button>
          </NavLink>
        </div>
        <ul className="card-wrapper">
          {productDetails.map((product) => {
            return (
              <li className="card">
                <img src={product["image"]} alt="" />
                <p>{product["name"]}</p>
                <p>{product["description"]}</p>
                <a
                  href={"joinclub/" + product["id"] + "/" + userDetails1["id"]}
                >
                  <button
                    style={{ padding: "20px 25px", marginBottom: "10px" }}
                  >
                    Join
                  </button>
                </a>
              </li>
            );
          })}
          {joinclubs.map((product) => {
            return (
              <li className="card">
                <img src={product["image"]} alt="" />
                <p>{product["name"]}</p>
                <p>{product["description"]}</p>
                <a
                  href={"leaveclub/" + product["id"] + "/" + userDetails1["id"]}
                >
                  <button
                    style={{ padding: "20px 25px", marginBottom: "10px" }}
                  >
                    Leave
                  </button>
                </a>
              </li>
            );
          })}
        </ul>
        <br />
        <div>
          <center>
            <h1>My Clubs</h1>
          </center>
        </div>
        <br />
        <br />

        <ul className="card-wrapper">
          {clubdetails.map((product) => {
            return (
              <li className="card">
                <img src={product["image"]} alt="" />
                <p>{product["name"]}</p>
                <p>{product["description"]}</p>
                <NavLink to={"/student/clubs/delete/" + product["id"]}>
                  <button
                    style={{ padding: "20px 25px", marginBottom: "10px" }}
                  >
                    Delete
                  </button>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default Club;
