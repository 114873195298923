import React, { useRef, useEffect, useState } from "react";
import "./Login.css";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

function Login() {
  const navigate = useNavigate();
  const email = useRef(null);
  const password = useRef(null);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    window.localStorage.removeItem("userdetails");
    window.localStorage.removeItem("user");
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email.current.value == "" && password.current.value == "") {
      alert("Please enter email and password");
    } else if (email.current.value == "") {
      alert("Please enter email");
    } else if (password.current.value == "") {
      alert("Please enter password");
    }

    const userdata = {};
    userdata["email"] = email.current.value;
    userdata["password"] = password.current.value;

    axios
      .post("https://axl8622.uta.cloud/api/public/log.php", userdata)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          let user_det = JSON.parse(response.data.user_data);
          if (String(user_det.validated) == "0") {
            navigate("/validateemail/" + userdata["email"]);
          } else {
            window.localStorage.setItem("user", user_det["user_type"]);
            window.localStorage.setItem("userdetails", response.data.user_data);

            if (user_det["user_type"] == "1") {
              navigate("/student/content");
            }
            if (user_det["user_type"] == "2") {
              navigate("/schooladmin");
            }
            if (user_det["user_type"] == "3") {
              navigate("/superadmin");
            }
            if (user_det["user_type"] == "4") {
              navigate("/business/products");
            }
          }
        } else if (response.data.status_code == 2) {
          alert("Invalid credentials, please try again");
        }
      });

    event.target.reset();
  };

  return (
    <>
      <div className="log">
        <p style={{ color: "Red" }}>{msg}</p>
        <div className="container" id="container">
          <div className="form-container sign-up-container">
            <form action="#">
              <h1>Create Account</h1>
              {/* <div className="social-container">
            <a href="#" className="social"><i className="fa-brands fa-facebook-f"></i></a>
            <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
            <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
          </div> */}
              {/* <span>or use your email for registration</span> */}
              <input type="text" placeholder="Name" />
              <input type="email" placeholder="Email" />
              <input type="password" placeholder="Password" />
              <button>Sign Up</button>
            </form>
          </div>
          <div className="form-container sign-in-container">
            <form onSubmit={handleSubmit}>
              <h1>Sign in</h1>
              {/* <div className="social-container">
            <a href="#" className="social"><i className="fa-brands fa-facebook-f"></i></a>
            <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
            <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
          </div>
          <span>or use your account</span> */}
              <input ref={email} type="email" placeholder="Email" />
              <input ref={password} type="password" placeholder="Password" />
              <NavLink to={"/resetpassword"}>
                <a href="#">Forgot your password?</a>
              </NavLink>
              <button>Sign In</button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Welcome Back!</h1>
                <p>
                  To keep connected with us please login with your personal info
                </p>
                <button className="ghost" id="signIn">
                  Sign In
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Hello, Friend!</h1>
                <p>Enter your personal details and start journey with us</p>
                <NavLink to={"/register"}>
                  <button className="ghost" id="signUp">
                    Sign Up
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
