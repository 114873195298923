import React from "react";
import Header from "../Header/Header";
import "./EditForm.css";

const submitForm = () => {
  let name = document.getElementById("name").value;
  let email = document.getElementById("email").value;
  let contact = document.getElementById("contact").value;
  let address = document.getElementById("address").value;
  let query = document.getElementById("query").value;

  fetch("https://axl8622.uta.cloud/api/public/about.php", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: name,
      email: email,
      contact: contact,
      address: address,
      query: query,
    }),
  }).then((response) => response.json())
  .then((responseJson) => {
    console.log(responseJson);
  });

  document.getElementById("name").value = "";
  document.getElementById("email").value = "";
  document.getElementById("contact").value = "";
  document.getElementById("address").value = "";
  document.getElementById("query").value = "";
};

const ContactForm = () => {

  return (
    <>
      <div>
        <div className="form-style-9">
          <h1>Contact Us</h1>
          <ul>
            <li>
              <input
                type="text"
                name="field1"
                className="field-style field-split align-left"
                placeholder={"Name"}
                id="name"
                required
              />
            </li>
            <li>
              <input
                type="email"
                name="field2"
                className="field-style field-split align-right"
                placeholder={"Email"}
                id="email"
                required
              />
            </li>
            <li>
              <input
                type="text"
                name="field3"
                className="field-style field-split align-left"
                placeholder="Phone"
                id="contact"
                required
              />
            </li>
            <li>
              <input
                type="text"
                name="field3"
                className="field-style field-split align-left"
                placeholder="Address"
                id="address"
                required
              />
            </li>
            <li>
              <input
                type="text"
                name="field3"
                className="field-style field-split align-left"
                placeholder="Query"
                id="query"
                required
              />
            </li>
            <li>
              <input type="submit" value="Submit" onClick={() => submitForm() } />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
