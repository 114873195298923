import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function DeletePost() {
  const navigate = useNavigate();
  const params = useParams();
  const [effectFlag, setEffectFlag] = useState(true);

  console.log(params.pid);

  useEffect(() => {
    if (effectFlag) {
      const usertype = window.localStorage.getItem("user");
      if (usertype == "2" || usertype == "1") {
        axios
          .get("https://axl8622.uta.cloud/api/public/deletepost.php?pid=" + params.pid)
          .then(function (response) {
            console.log(response.data);
            if (response.data.status_code == 1) {
              if (window.localStorage.getItem("user") == "2")
                navigate("/schooladmin/content");
              else if (window.localStorage.getItem("user") == "1")
                navigate("/student/content");
              else if (window.localStorage.getItem("user") == "3")
                navigate("/superadmin/content");
              else if (window.localStorage.getItem("user") == "4")
                navigate("/business/products");
              else {
                alert("Record deleted, login again to see response");
                navigate("/login");
              }
            }
          });
      } else {
        alert(
          "You are not authorized to access this page. Login with business owner credentials"
        );
        navigate("/login");
      }
    }
    setEffectFlag(false);
  });

  return <p>DELETE POST PAGE</p>;
}

export default DeletePost;
